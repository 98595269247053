import * as React from "react";
import img from "../images/bernhard_moser.png";
import control_panel from "../static/icons/control-panel.svg";
import drilling from "../static/icons/drilling.svg";
import factory from "../static/icons/factory.svg";
import jeep from "../static/icons/jeep.svg";
import mining from "../static/icons/mining.svg";
import { MainLayout } from "../layouts/MainLayout";

// markup
const About = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="about-us">
            <div className="columns">
              <div className="column">
                <section className="who-we-are">
                  <div className="page-title">
                    <h1>ABOUT</h1>
                    <h3>AVT Sarl</h3>
                  </div>
                  <p className="page-description">
                    AVT Sarl is a leading consulting partner for mining
                    industry, specialising in design, consultancy and supply of
                    driveline technologies. We are a leading supplier of
                    driveline parts, security systems and certified spare-parts
                    to mining units, industrial plants and military vehicles.
                  </p>
                </section>
                <section className="what-we-do">
                  <h3 className="subtitle has-text-weight-bold">
                    Sectors Serviced
                  </h3>
                  <p className="page-description">
                    We at AVT, has acquired in-depth expertise, as well as
                    possess high levels of technical acumen and understanding,
                    enhanced by accomplishing a series of projects, in these
                    engineering domains.{" "}
                  </p>
                  <ul>
                    <li>
                      <figure>
                        <img src={mining} alt="security" />
                      </figure>
                      <p>Industrial Plants</p>
                    </li>
                    <li>
                      <figure>
                        <img src={drilling} alt="security" />
                      </figure>
                      <p>Mining Industry</p>
                    </li>
                    <li>
                      <figure>
                        <img src={factory} alt="security" />
                      </figure>
                      <p>Cement Industry</p>
                    </li>
                    <li>
                      <figure>
                        <img src={jeep} alt="security" />
                      </figure>
                      <p>Trucks & Armoured Vehicles </p>
                    </li>
                    <li>
                      <figure>
                        <img src={control_panel} alt="security" />
                      </figure>
                      <p>Design & Technology Consultancy</p>
                    </li>
                  </ul>
                  <h4 className="is-size-3">
                    Technical Expertise and Professional Approach
                  </h4>
                  <p>
                    Our goal as the engineering parts supplier is to deliver
                    best-in-class engineering solutions based on the client
                    requirements, meeting the exacting needs of accuracy and
                    sophistication. We are well-equipped to provide highly
                    skilled technical advice and support, with our direct access
                    to the leading brands, products and services. We provide an
                    array of technical services meeting industry-specific
                    requirements across a diverse clientele.
                  </p>
                  <p>
                    We bring the much-sought after excellence in operations by
                    liasoning and communicating with stake-holders at each
                    level, achieved with a well-managed technical support team,
                    smooth business processes and operational excellence.
                  </p>
                  <p>
                    AVT Sarl corporate office is located at St. Cyprien, France.
                    We are a subsidiary of the OH24 Holding, headquartered in
                    France.
                  </p>
                </section>
              </div>
              <div className="column">
                <section className="founder">
                  <figure className="founder-image">
                    <img src={img} alt="mining-image" />
                  </figure>
                  <h2 className="title">Bernhard Moser</h2>
                  <h3 className="subtitle">Managing Director - AVT Sarl</h3>
                  <p>
                    Bernhard Moser is the Chief Executive Officer and Managing
                    Director of AVT Sarl. He oversees the strategic expansion
                    and operations of the business across the global markets,
                    and leads the product marketing and engineering efforts at
                    AVT. He has deep expertise in the business development,
                    product planning & distribution, technical support and
                    advisory, for diverse applications in the On- & Off-highway
                    markets.
                  </p>
                  <p>
                    Mr. Moser is Mechanical engineer from RWTH Aachen University
                    in Germany, with a Masters from McGill University in
                    Montreal, Canada. He has more than two decades of
                    experience, directing sales and business units across
                    international markets.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default About;
